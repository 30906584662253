<template>
  <div id="app">
    <!-- <NavBar /> -->
    <div id="background-body">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
// import NavBar from "./components/Header/NavBar.vue";
// import FirstpageApp from "./components/FirstPage/FirstpageApp.vue"
import Footer from "./components/Footer/Footer.vue";

export default {
  name: "Binj",
  meta: { title: "Binj" },

  components: {
    // NavBar,

    Footer,
  },
};
</script>
<style  >
.movie-row {
  height: 210px;

  /* scroll-behavior: smooth; */
}
/* display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch; */
.alighcenter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.list-group-item {
  background-color: transparent !important;
  border: none !important;
}
.bg-dark-o {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100% !important;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}
.wrapper {
  background-size: cover;
  background: url("./assets/Movie Cover + BG.png");
  color: #eee;
  width: auto;
  height: 50vh;
  /* display: flex; */
  background-position: center;

  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  animation: animation 80s linear infinite;
}

@keyframes animation {
  100% {
    background-position: 0px -3000px;
  }
}
.op- {
  opacity: 0.5;
}
.op- :hover {
  opacity: 1;
}
.op {
  opacity: 1;
}
.image-heghit {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100%;
}
.hoverHandeler {
  /* transition: 0.2s; */
  height: 100%;
  overflow: hidden;
}
/* .Ccol-3 {
  transition: 0.5s;
  display: none;
  flex: 0 0 0;
  max-width: 0;
}
.Ccol-7 {
  transition: 0.5s;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
} */
/* .showHandeler {
  display: none;
}
.hoverHandeler:hover .col-md-8 {
  /* transition: 0.5s; */
/* flex: 0 0 33.333333%;
  max-width: 33.333333%;
} */
/* .hoverHandeler:hover .col-2 {
  flex: 0 0 25%;
  max-width: 25%;
  display: block;
} */
h5 {
  transition: 0.3s;
}
/* .hoverHandeler:hover h5 {
  /* transition: 0.5s; */
/* font-size: 1rem; */
/* }  */
/* .hoverHandeler:hover .showHandeler {
  display: block;
} */
.voter svg {
  cursor: pointer;
}
.slow {
  /* transition: 0.5s; */
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.li-border {
  background: #91909070;
  border: 1px solid rgb(172, 172, 172);
  border-radius: 10px;
}
.li-border .text-bettwen {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.li-border .text-center2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: center;
  align-items: center;
  align-content: center; */
}
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
.border-white {
  /* border: 1px solid rgb(172, 172, 172); */
}
.btn-info {
  background-color: #17a2b8de !important;
  border-color: #14b5ce !important  ;
}

@import url("./assets/font/stylesheet.css");
#background-body {
  position: relative;
  /* background-color: black; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* animation: animateSectionBackground infinite 30s; */
}
/* #background-body::before {
  background: rgba(0, 0, 0, 0.808);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 150px;
} */
body {
background: rgb(0,0,0);
background: linear-gradient(90deg, rgb(20, 0, 0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgb(20, 0, 0) 100%);
  height: 100vh;
  font-family: "Axiforma";
  margin: 0;
  padding: 0;
}
.rounded-8px {
  border-radius: 8px;
}
.flex-between {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}
.watches {
  font-size: 0.85rem;
}
/* @keyframes animateSectionBackground {
  00%,
  11% {
    background-image: url("https://i.pinimg.com/564x/8d/46/66/8d4666f53621880b7b544b764d1c96ed.jpg");
  }
  12%,
  24% {
    background-image: url("https://i.pinimg.com/564x/ef/22/bf/ef22bfe07c9cfde26bcb7d8da8c3f48e.jpg");
  }
  25%,
  36% {
    background-image: url("https://i.pinimg.com/originals/eb/93/f5/eb93f50ed7347ed22e17a9c112eb6449.jpg");
  }
  37%,
  49% {
    background-image: url("https://i.pinimg.com/564x/f8/e6/fd/f8e6fd8e23848bc8ce934e56449941cb.jpg");
  }
  50%,
  61% {
    background-image: url("https://i.pinimg.com/564x/99/65/6f/99656f57e4ad07d562ca8fd1e7aa398b.jpg");
  }
  62%,
  74% {
    background-image: url("https://i.pinimg.com/564x/2d/70/46/2d704677284dbe9597bd77a2a1e863b5.jpg");
  }
  75%,
  86% {
    background-image: url("https://i.pinimg.com/564x/33/d6/f8/33d6f8bb229b0c647f406779b597eb06.jpg");
  }
  87%,
  99% {
    background-image: url("https://i.pinimg.com/564x/68/3f/71/683f7123e612f0b29dba88944562357c.jpg");
  }
} */
@media only screen and (max-width: 600px) {
  .wrapper {
    height: 30vh;
  }
  .watches {
    font-size: 0.75rem;
  }
  .list-group-item {
    padding: 0.25rem 1rem;
  }
  .movie-row {
    height: 144px;
  }
  .resposiveFotter {
    height: 450px;
  }
  /* .responsive-flex {
    height: 200px;
  } */

  .btn-info {
    height: 200px;
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }
  .h-50px {
    text-overflow: ellipsis;
    min-height: 80px !important;
  }
}
</style>


