import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
import VueClipboard from "vue-clipboard2";
import Notifications from "vue-notification";
import VuePreloaders from "vue-preloaders";
import VueSocialSharing from "vue-social-sharing";
import UUID from "vue-uuid";
import VueRouter from "vue-router";
import routes from "./routes";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueAnalytics from "vue-analytics";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

Vue.use(VueSmoothScroll);
Vue.use(VueRouter);

Vue.use(UUID);
Vue.use(VueSocialSharing);
Vue.use(VuePreloaders /*{ options }*/);

Vue.use(VueClipboard);
Vue.use(Notifications);

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: "https://api.binj.tech/",
    });
  },
});

const router = new VueRouter({ mode: "history", routes });
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: "UA-178628132-1",
  router,
});
new Vue({
  router,

  render: (h) => h(App),

  // created() {
  //   const close = this.$preloaders.open({
  //     assetSrc: "https://i.giphy.com/media/xTkcEQACH24SMPxIQg/giphy.webp",
  //     overlayStyle: {
  //       width: "100 %",
  //       hegiht: "100vh",
  //       backgroundColor: "#161618",
  //       opacity: 1,
  //     },
  //   });
  //   setTimeout(close, 1500);
  // },
}).$mount("#app");
