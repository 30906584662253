<template>
  <div>
    <div class="fixed-bottom col-md-8 mx-auto p-0" v-if="cookie">
      <!-- < class=" p-4"> -->
      <div class="row bg-light-05 p-4">
        <div class="col-md-7 text-center text-md-left mb-md-0 mb-3">
          <p class="m-0">like most websites <span class="text-primary"> Binj Room</span>uses cookies</p>
          <small>Please accept cookies for optimal performance</small>
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-6 text-center text-md-left mt-1 ">
              <button class="btn btn-secondary" @click="cookie=false">cancel</button>
            </div>
            <div class="col-6 text-center text-md-left mt-1">
              <button class="btn btn-success" @click="cookie=false">Accept</button>
            </div>
          </div>
        </div>
      </div>

      <!-- </b-nav> -->
    </div>
    <notifications group="foo" />
    <div>
      <b-nav class="container-fluid bg-light px-md-5">
        <b-nav-item class="mr-auto" style="z-index: 99">
          <img src="../../assets/Artboard 1-8.png" width="50px" class="mt-3" />
        </b-nav-item>
        <b-nav-item class="ml-auto">
          <img src="../../assets/Artboard 2-8.png" width="35px" />
        </b-nav-item>
      </b-nav>
      <div class="wrapper col-12 p-0" ref="TopPage" id="TopPage">
        <div class="top-text bg-dark-o py-5 text-center rounded">
          <h1 class="container">{{ Global_title }}</h1>
        </div>
      </div>
    </div>
    <div class="col-lg-6 offset-lg-3 bg-light rounded-bottom">
      <div class="row py-2 py-md-3">
        <div class="col-md-4 mb-3 mb-md-0">
          <p class="m-0 text-nowrap">Share it with your friends</p>
        </div>
        <div class="col-md-6 ml-auto text-right">
          <div class="row">
            <div class="col text-center" @click="shareHandeler">
              <!-- <ShareNetwork :url="message" title="binj movie rater" v-if="message"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="100%"
                viewBox="-21 0 512 512"
                width="40%"
              >
                <path
                  d="m453.332031 85.332031c0 38.292969-31.039062 69.335938-69.332031 69.335938s-69.332031-31.042969-69.332031-69.335938c0-38.289062 31.039062-69.332031 69.332031-69.332031s69.332031 31.042969 69.332031 69.332031zm0 0"
                />
                <path
                  d="m384 170.667969c-47.0625 0-85.332031-38.273438-85.332031-85.335938 0-47.058593 38.269531-85.332031 85.332031-85.332031s85.332031 38.273438 85.332031 85.332031c0 47.0625-38.269531 85.335938-85.332031 85.335938zm0-138.667969c-29.417969 0-53.332031 23.9375-53.332031 53.332031 0 29.398438 23.914062 53.335938 53.332031 53.335938s53.332031-23.9375 53.332031-53.335938c0-29.394531-23.914062-53.332031-53.332031-53.332031zm0 0"
                />
                <path
                  d="m453.332031 426.667969c0 38.289062-31.039062 69.332031-69.332031 69.332031s-69.332031-31.042969-69.332031-69.332031c0-38.292969 31.039062-69.335938 69.332031-69.335938s69.332031 31.042969 69.332031 69.335938zm0 0"
                />
                <path
                  d="m384 512c-47.0625 0-85.332031-38.273438-85.332031-85.332031 0-47.0625 38.269531-85.335938 85.332031-85.335938s85.332031 38.273438 85.332031 85.335938c0 47.058593-38.269531 85.332031-85.332031 85.332031zm0-138.667969c-29.417969 0-53.332031 23.9375-53.332031 53.335938 0 29.394531 23.914062 53.332031 53.332031 53.332031s53.332031-23.9375 53.332031-53.332031c0-29.398438-23.914062-53.335938-53.332031-53.335938zm0 0"
                />
                <path
                  d="m154.667969 256c0 38.292969-31.042969 69.332031-69.335938 69.332031-38.289062 0-69.332031-31.039062-69.332031-69.332031s31.042969-69.332031 69.332031-69.332031c38.292969 0 69.335938 31.039062 69.335938 69.332031zm0 0"
                />
                <path
                  d="m85.332031 341.332031c-47.058593 0-85.332031-38.269531-85.332031-85.332031s38.273438-85.332031 85.332031-85.332031c47.0625 0 85.335938 38.269531 85.335938 85.332031s-38.273438 85.332031-85.335938 85.332031zm0-138.664062c-29.417969 0-53.332031 23.933593-53.332031 53.332031s23.914062 53.332031 53.332031 53.332031c29.421875 0 53.335938-23.933593 53.335938-53.332031s-23.914063-53.332031-53.335938-53.332031zm0 0"
                />
                <path
                  d="m135.703125 245.761719c-7.425781 0-14.636719-3.863281-18.5625-10.773438-5.824219-10.21875-2.238281-23.253906 7.980469-29.101562l197.949218-112.851563c10.21875-5.867187 23.253907-2.28125 29.101563 7.976563 5.824219 10.21875 2.238281 23.253906-7.980469 29.101562l-197.953125 112.851563c-3.328125 1.898437-6.953125 2.796875-10.535156 2.796875zm0 0"
                />
                <path
                  d="m333.632812 421.761719c-3.585937 0-7.210937-.898438-10.539062-2.796875l-197.953125-112.851563c-10.21875-5.824219-13.800781-18.859375-7.976563-29.101562 5.800782-10.238281 18.855469-13.84375 29.097657-7.976563l197.953125 112.851563c10.21875 5.824219 13.800781 18.859375 7.976562 29.101562-3.945312 6.910157-11.15625 10.773438-18.558594 10.773438zm0 0"
                />
              </svg>

              <!-- </ShareNetwork> -->
            </div>
            <div class="col text-center">
              <ShareNetwork
                network="linkedin"
                :url="message"
                title="you can rate you favorit movies"
                description="you can rate you favorit movies"
                hashtags="binj,binj.com,imdb,movie,netfilx"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40%"
                  height="100%"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                  />
                </svg>
              </ShareNetwork>
            </div>
            <div class="col text-center">
              <ShareNetwork
                network="twitter"
                :url="message"
                title="you can rate you favorite movies"
                description="you can rate you favorite movies"
                quote="The hot reload is so fast it's near instant. - Evan You"
                hashtags="binj,binj.com,imdb,movie,netfilx"
                twitterUser="youyuxi"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  height="100%"
                  width="40%"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                >
                  \
                  <path
                    d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016    c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992    c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056    c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152    c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792    c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44    C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568    C480.224,136.96,497.728,118.496,512,97.248z"
                  />
                </svg>
              </ShareNetwork>
            </div>
            <div class="col text-center">
              <ShareNetwork
                network="facebook"
                :url="message"
                title="you can rate you favorit movies"
                description="you can rate you favorit movies"
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="binj,binj.com,imdb,movie,netfilx"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Bold"
                  enable-background="new 0 0 24 24"
                  height="100%"
                  viewBox="0 0 24 24"
                  width="40%"
                >
                  <path
                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                  />
                </svg>
              </ShareNetwork>
            </div>
            <div class="col text-center" @click="copylink()">
              <svg
                height="100%"
                width="40%"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 477.389 477.389"
                style="enable-background: new 0 0 477.389 477.389"
                xml:space="preserve"
              >
                <path
                  d="M451.209,68.647c-16.787-16.799-39.564-26.234-63.312-26.226v0c-23.739-0.056-46.516,9.376-63.266,26.197L209.056,184.194    c-22.867,22.903-31.609,56.356-22.869,87.518c2.559,9.072,11.988,14.352,21.06,11.793c9.072-2.559,14.352-11.988,11.793-21.06    c-5.388-19.271,0.018-39.95,14.148-54.118L348.763,92.768c21.608-21.613,56.646-21.617,78.259-0.008    c21.613,21.608,21.617,56.646,0.009,78.259L311.456,286.594c-7.574,7.584-17.193,12.797-27.682,15.002    c-9.228,1.921-15.151,10.959-13.23,20.187c1.652,7.935,8.657,13.613,16.762,13.588c1.193,0.001,2.383-0.125,3.55-0.375    c16.951-3.575,32.494-12.007,44.732-24.269l115.576-115.558C486.114,160.243,486.134,103.598,451.209,68.647z"
                />

                <path
                  d="M290.702,206.142c-2.559-9.072-11.988-14.352-21.06-11.793s-14.352,11.988-11.793,21.06    c5.388,19.271-0.018,39.95-14.148,54.118L128.125,385.103c-21.608,21.613-56.646,21.617-78.259,0.008    c-21.613-21.608-21.617-56.646-0.009-78.259l115.576-115.593c7.562-7.582,17.17-12.795,27.648-15.002    c9.243-1.849,15.237-10.84,13.388-20.082s-10.84-15.237-20.082-13.388c-0.113,0.023-0.225,0.046-0.337,0.071    c-16.954,3.579-32.502,12.011-44.749,24.269L25.725,282.703c-34.676,35.211-34.242,91.865,0.969,126.541    c34.827,34.297,90.731,34.301,125.563,0.008l115.575-115.593C290.7,270.756,299.442,237.303,290.702,206.142z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="list-demo" class="container">
      <!-- {{roomId}} -->
      <movies :items="items" :roomId="roomId" v-on:sendValue="sendValue" />
    </div>
    <div class="wrapper col-12 p-0 resposiveFotter">
      <div class="top-text bg-dark-o py-5 text-center rounded">
        <div class="col-12 h-100">
          <div class="row h-100">
            <div class="col-md-3 col-6 alighcenter responsive-flex">
              <button
                @click="cahngeRoute('-')"
                class="btn btn-info col-md-6 d-block py-2 py-md-4 text-center"
              >
                <p class="h-50px alighcenter">{{ prevTitle }}</p>
                <div class="col-12">
                  <img
                    class="mt-3"
                    src="../../assets/prevent.svg"
                    height="25%"
                    width="25%"
                  />
                </div>
              </button>
              <!-- <router-link
                :to="first_ID"
                class="btn btn-info col-md-6 d-block py-2 py-md-4 text-center"
                v-else
              >
                <p class="h-50px alighcenter">Rank Your Marvel Cinematic Universe films</p>
                <div class="col-12">
                  <img class="mt-3" src="../../assets/prevent.svg" height="25%" width="25%" />
                </div>
              </router-link>-->
            </div>
            <div class="col-md-6 alighcenter mb-4 pb-1 d-none d-md-flex h-100">
              <div class="input-group mb-3">
                <div class="col-12 text-nowarp">
                  <h4>Subscribe for more topics</h4>
                </div>
                <b-input-group class="mt-3">
                  <template v-slot:append>
                    <b-input-group-text
                      class="btn btn-warning"
                      @click="submitEmail"
                      >submit</b-input-group-text
                    >
                  </template>
                  <b-form-input
                    placeholder="Email"
                    v-model="email"
                    type="email"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="col-md-3 col-6 alighcenter responsive-flex">
              <button
                @click="cahngeRoute('+')"
                class="btn btn-info col-md-6 d-block py-2 py-md-4 text-center"
              >
                <p class="h-50px alighcenter">{{ nexTitle }}</p>
                <div class="col-12">
                  <img
                    class="mt-3"
                    src="../../assets/1024px-Next_Arrow.svg"
                    height="25%"
                    width="25%"
                  />
                </div>
              </button>

              <!-- <router-link :to="first_ID" class="btn btn-info col-md-6 d-block py-2 py-md-4" v-else>
                <p class="h-50px alighcenter">Rank Your Marvel Cinematic Universe films</p>
                <div class="col-12">
                  <img
                    class="mt-3"
                    src="../../assets/1024px-Next_Arrow.svg"
                    height="25%"
                    width="25%"
                  />
                </div>
              </router-link>-->
            </div>
            <div class="col-md-6 alighcenter mb-4 pb-1 d-block mt-5 d-md-none">
              <div class="input-group mb-3">
                <div class="col-12 text-nowarp">
                  <h4>Subscribe for more topics</h4>
                </div>
                <b-input-group class="mt-3">
                  <template v-slot:append>
                    <b-input-group-text
                      class="btn btn-warning"
                      @click="submitEmail"
                      >submit</b-input-group-text
                    >
                  </template>
                  <b-form-input
                    placeholder="Email"
                    v-model="email"
                    type="email"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-nav class="container-fluid bg-light px-5"> -->

    <!-- </b-nav> -->
  </div>
</template>
<script>
// import data from "./data.json";
import { uuid } from "vue-uuid";
import movies from "../movie/movies";

export default {
  components: {
    movies,
  },
  watch: {
    $route(to, from) {
      if (to.hash != "") {
        window.history.replaceState(
          {},
          document.title,
          to.path.replace("/get/", "")
        );
      }
      if (to.path !== from.path) {
        this.lastRoomId = from.path;
        if (to.path.replace("/get/", "") == "marvel") {
          this.roomId = this.idArray[1].id;
        } else if (to.path.replace("/get/", "") == "contagion") {
          this.roomId = this.idArray[0].id;
        } else if (to.path.replace("/get/", "") == "chadwick") {
          this.roomId = this.idArray[2].id;
        }

        this.getcahgeData();
      }
    },
  },
  data() {
    return {
      cookie:false,
      nexTitle: "Your Top contagion movies",
      prevTitle: "Your Favorite Chadwick Boseman performances",
      first_ID: "f2cf43e4-db05-4c25-b52a-8979a50d5ea7",
      Global_title: "Rank Your Marvel Cinematic Universe films",
      index: 1,
      // title: "",
      idArray: [
        {
          id: process.env.VUE_APP_SECOND_ID,
          url: "contagion",
          title: "Your Top contagion movies",
        },
        {
          id: "f2cf43e4-db05-4c25-b52a-8979a50d5ea7",
          url: "marvel",
          title: "Rank Your Marvel Cinematic Universe films",
        },
        {
          id: process.env.VUE_APP_THIRD_ID,
          url: "chadwick",
          title: "Your Favorite Chadwick Boseman performances",
        },
      ],
      lastRoomId: process.env.VUE_APP_SECOND_ID,
      roomId: "",
      second_ID: process.env.VUE_APP_SECOND_ID,
      third_ID: process.env.VUE_APP_THIRD_ID,
      url: "",
      email: "",
      title: "binj",
      message2: "sadsadassad",
      sendRequest: false,
      message: null,
      show: false,
      counter: 0,
      interval: 0,
      items: null,
      items1: null,
      items2: null,
      sortable: [],
    };
  },

  mounted() {
    if (this.$route.params.id == "marvel") {
      this.roomId = this.idArray[1].id;
      this.Global_title = this.idArray[1].title;
      this.nexTitle = this.idArray[2].title;
      this.prevTitle = this.idArray[0].title;
      this.index = 1;
    } else if (this.$route.params.id == "contagion") {
      this.Global_title = this.idArray[0].title;
      this.roomId = this.idArray[0].id;
      this.nexTitle = this.idArray[1].title;
      this.prevTitle = this.idArray[2].title;
      this.index = 0;
    } else if (this.$route.params.id == "chadwick") {
      this.index = 2;
      this.Global_title = this.idArray[2].title;
      this.roomId = this.idArray[2].id;
      this.nexTitle = this.idArray[0].title;
      this.prevTitle = this.idArray[1].title;
    }

    // this.roomId = ;

    this.url = window.location.href;

    if (!localStorage.getItem("uuid")) {
      localStorage.setItem("uuid", uuid.v4());
      this.cookieHandeler();
    }

    // this.getData();
    this.message = window.location.href;

    this.changeValues();
    this.sendPageView();
  },

  methods: {
    cookieHandeler() {
      setTimeout(() => {
          this.cookie=true
      }, 5000);

    },
    cahngeRoute(value) {
      let url = "";
      if (this.index == 1) {
        if (value == "+") {
          this.index = 2;
          this.nexTitle = this.idArray[0].title;
          this.prevTitle = this.idArray[1].title;
        } else {
          this.index = 0;
          this.nexTitle = this.idArray[1].title;
          this.prevTitle = this.idArray[2].title;
        }

        let element = this.idArray[this.index];
        this.Global_title = element.title;

        url = "/get/" + element.url;
      } else if (this.index == 2) {
        if (value == "+") {
          this.index = 0;
          this.nexTitle = this.idArray[1].title;
          this.prevTitle = this.idArray[2].title;
        } else {
          this.index = 1;
          this.nexTitle = this.idArray[2].title;
          this.prevTitle = this.idArray[0].title;
        }
        let element = this.idArray[this.index];
        this.Global_title = element.title;
        url = "/get/" + element.url;
      } else {
        if (value == "+") {
          this.index = 1;
          this.nexTitle = this.idArray[2].title;
          this.prevTitle = this.idArray[0].title;
        } else {
          this.index = 2;
          this.nexTitle = this.idArray[0].title;
          this.prevTitle = this.idArray[1].title;
        }
        let element = this.idArray[this.index];
        this.Global_title = element.title;
        url = "/get/" + element.url;
      }

      this.$router.push(url);
    },
    submitEmail() {
      var re = /\S+@\S+\.\S+/;

      let checkRegex = re.test(String(this.email).toLowerCase());
      if (checkRegex) {
        this.$http
          .post("https://api.binj.tech/v1/newsletter/subscribe/", {
            email: this.email,
          })
          .then((res) => {
            this.$ga.event({
              eventCategory: "submit",
              eventAction: "submit",
              eventLabel: "email submission",
              eventValue: 123,
            });
            this.email = "";
            if (res.data.is_added) {
              this.$notify({
                group: "foo",
                title: "THANK YOU !",
                text: " for submit your Email !",
                color: "warning",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Oops !",
                text: " something goes wrong !",
                color: "warning",
              });
            }
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Pleas check your input !",
          text: " enter valid Email",
          color: "warning",
        });
      }
    },
    shareHandeler() {
      navigator
        .share({
          title: "Bits and pieces: Web Share API article",
          text: "Web Share API feature is awesome. You must check it",
          url: this.url,
        })
        .then(function () {
          alert("Shareing successfull");
        })
        .catch(function () {
          alert("Sharing failed");
        });
      // } else {
      //   alert("Sorry! Your browser does not support Web Share API");
      // }
    },
    onError(err) {
      alert(err);
      console.log(err);
    },
    onSuccess(err) {
      console.log(err);
    },

    copylink() {
      this.$copyText(this.message).then(
        () => {
          this.$notify({
            group: "foo",
            title: "Thank you to copy this url",
            text: "now you can paste it anywhere",
          });
        },
        (e) => {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    changeValues() {
      setTimeout(() => {
        this.getData();
      }, this.interval * 1000);
    },
    sendPageView() {
      this.$http.post(`v1/open-chart/${this.roomId}/`);
    },
    getcahgeData() {
      const myEl = this.$refs.TopPage;
      this.$smoothScroll({
        // window.scrollTo(0, 0);
        scrollTo: myEl,
        // hash: "#sampleHash", // required if updateHistory is true
      });
      //
      this.$ga.page(this.roomId);
      this.$http
        .get(`v1/open-chart/${this.roomId}/`, {
          headers: {
            "Binj-User": localStorage.getItem("uuid"),
          },
        })
        .then((res) => {
      
          this.sendPageView();
          this.interval = res.headers["cache-control"].match(/\d+/)[0];
          this.items = res.data.items;
          this.counter++;
        });
    },
    getData() {
      this.$http
        .get(`v1/open-chart/${this.roomId}/`, {
          headers: {
            "Binj-User": localStorage.getItem("uuid"),
          },
        })
        .then((res) => {
              // console.log(res)
          this.interval = res.headers["cache-control"].match(/\d+/)[0];
          this.items = res.data.items;
          this.counter++;

          this.changeValues();
          this.createImage();
        });
    },
    createImage() {
      let images = [];
      this.items.forEach((element) => {
        images.push(element.poster);
      });
    },

    sendValue(value) {
      this.items = value;
      this.counter++;
    },
    // vote(id, vote) {
    //   if (this.sendRequest) {
    //     // alert("waite");
    //   } else {
    //     this.sendRequest = true;
    //     this.$http
    //       .post(
    //         `v1/open-chart/18f78e73-2df9-4688-a565-39c85ea3c4a0/vote/`,
    //         {
    //           movie_id: id,
    //           vote: vote,
    //         },
    //         {
    //           headers: {
    //             "Binj-User": localStorage.getItem("uuid"),
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         this.sendRequest = false;
    //       });
    //   }
    // },
  },
};
</script>
<style >
.bg-light-05 {
  background: rgba(255, 255, 255, 0.8);
}
.h-50 {
  height: 50%;
}
.btn-info {
  min-height: 200px;
}
@media only screen and (max-width: 600px) {
  .resposiveFotter {
    height: 450px;
  }
  /* .responsive-flex {
    height: 200px;
  } */

  .btn-info {
    min-height: 200px;
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }
  .h-50px {
    text-overflow: ellipsis;
    min-height: 80px !important;
  }
}
</style>
