<template>
  <div>
    <b-nav class="container-fluid bg-light px-5">
      <b-nav-item class="mr-auto">
        <img src="../../assets/Artboard 1-8.png" width="40px" class="mt-3" />
      </b-nav-item>
      <b-nav-item class="ml-auto">
        <img src="../../assets/Artboard 2-8.png" width="30px" />
      </b-nav-item>
    </b-nav>
    <div class="wrapper col-12 p-0" ref="images">
      <div class="top-text bg-dark-o py-5 text-center rounded">
        <h1>Forget Password</h1>
      </div>
    </div>

    <div class="alighcenter h-50vh">
      <form class="col-md-3" @submit="checkForm">
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input
            type="password"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            v-model="password1"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword2">Confirm password</label>
          <input
            v-model="password2"
            type="password"
            class="form-control"
            id="exampleInputPassword2"
            placeholder="confirm password"
          />
        </div>

        <button
          type="submit"
          class="btn btn-primary col-12"
          :disabled="displaySubmit"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      password1: null,
      password2: null,
      query: null,
      displaySubmit: true,
    };
  },
  mounted() {
    this.query = this.$route.query.token;
    // console.log(this.$route.query.token);
  },
  watch: {
    password2() {
      if (this.password1.length >= 8 && this.password1 == this.password2) {
        this.displaySubmit = false;
      }
    },
  },
  methods: {
    checkForm(e) {
      e.preventDefault();
      if (this.password1.length >= 8 && this.password1 == this.password2) {
        this.$http
          .post(
            `${process.env.VUE_APP_RESETPASS}/v1/auth/reset-password/verify/`,
            {
              token: this.query,
              password: this.password1,
            }
          )
          .then(() => {
            alert("done !");
            window.location = "https://google.com";
          })
          .catch(() => {
            alert("oops something goes wrong");
          });
      } else {
        alert("please check your input");
      }
    },
  },
};
</script>
<style scoped>
.h-50vh {
  z-index: 99;
  height: 50vh;
}
.alighcenter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.list-group-item {
  background-color: transparent !important;
}
.bg-dark-o {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100% !important;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
}
.wrapper {
  background-size: cover;
  background: url("../../assets/Movie Cover + BG.png");
  color: #eee;
  width: auto;
  height: 40vh;
  /* display: flex; */
  background-position: center;

  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  animation: animation 80s linear infinite;
}

@keyframes animation {
  100% {
    background-position: 0px -3000px;
  }
}
.op- {
  opacity: 0.5;
}
.op- :hover {
  opacity: 1;
}
.op {
  opacity: 1;
}
.image-heghit {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100%;
}
.hoverHandeler {
  /* transition: 0.2s; */
  height: 100%;
  overflow: hidden;
}
</style>