import firstPage from "./components/pages/firstPage.vue";
import forgetPassword from "./components/pages/forgetPassword.vue";
import NotFound from "./components/pages/404";
const routes = [
  {
    path: "/",
    redirect: () => {
      return "/get/marvel";
    },
  },
  {
    path: "/get/:id",
    component: firstPage,
    meta: {
      title: "BINJ forget-password",
    },
  },

  {
    path: "/reset-password",
    component: forgetPassword,
    meta: {
      title: "BINJ forget-password",
    },
  },
  { path: "*", component: NotFound },
];

export default routes;
